<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.services.item-status") }}
        </div>
        <template v-slot:right>
          <button
            @click="
              submitFormState = true;
              initSubmit();
            "
            v-if="selectedResourceServices && selectedResourceServices.length"
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="serviceItem">
      <li class="clebex-item-section-item full-right-underline">
        <div
          class="clebex-item-content-wrapper"
          style="cursor: pointer;"
          @click="showInfoBox = !showInfoBox"
        >
          <div
            class="clebex-section-input"
            style="cursor: pointer;"
            @click="showInfoBox = !showInfoBox"
          >
            <label
              class="clebex-section-input-label"
              for="orderNumber"
              style="cursor: pointer;"
              @click="showInfoBox = !showInfoBox"
              >{{ displayLabelName("services.service-items.name") }}</label
            >
            <span
              style="cursor: pointer; color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
              @click="showInfoBox = !showInfoBox"
            >
              {{ serviceItem.name }}</span
            >
          </div>
          <span class="follow-up-icon-item" style="cursor: pointer;">
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              style="cursor: pointer; margin-right: 15px; margin-top: 5px;"
              :style="[
                !showInfoBox
                  ? 'transform: rotate(90deg)'
                  : 'transform: rotate(-90deg)',
              ]"
            />
          </span>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="description">{{
              displayLabelName("services.service-items.description")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ serviceItem.description }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="type">{{
              displayLabelName("services.service-items.type")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="orderNumber"
              disabled="true"
              v-model="serviceItem.type"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="orderNumber">{{
              displayLabelName("services.service-items.code")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="orderNumber"
              disabled="true"
              v-model="serviceItem.code"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="orderNumber">{{
              displayLabelName("services.services.quantity")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="orderNumber"
              disabled="true"
              v-model="serviceItem.quantity"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="orderNumber">{{
              displayLabelName("services.services.note")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="orderNumber"
              disabled="true"
              v-model="serviceItem.note"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="orderNumber">{{
              displayLabelName("services.service-items.price")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="orderNumber"
              disabled="true"
              v-model="price"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="status">{{
              displayLabelName("services.services.status")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="orderNumber"
              disabled="true"
              v-model="serviceItem.service_status_name"
            />
          </div>
        </div>
      </li>
    </ul>

    <!-- STATUSES - START -->
    <ul
      class="clebex-item-section pill"
      v-if="listOfServiceStatuses && listOfServiceStatuses.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="serviceStatus in listOfServiceStatuses"
        :key="serviceStatus.id"
      >
        <button
          v-if="showStatus(serviceStatus.type) && !checked(serviceStatus.type)"
          class="clebex-item-content-wrapper"
          @click="statusUpdate(serviceStatus.type)"
          :style="[
            !disableStatus(serviceStatus.type) && !checked(serviceStatus.type)
              ? 'background-color: #24b7af; color: white !important;'
              : '',
          ]"
        >
          <span class="label">
            <span
              class="highlight"
              :style="[
                disableStatus(serviceStatus.type) &&
                !checked(serviceStatus.type)
                  ? 'color: lightgray;'
                  : '',
                !disableStatus(serviceStatus.type) &&
                !checked(serviceStatus.type)
                  ? 'color: white;'
                  : '',
              ]"
              >{{ serviceStatus.name }}</span
            >
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="checked(serviceStatus.type)"
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
    <!-- STATUSES - END -->
    <button type="submit" style="display: none;" ref="submitFormBtn">
      Submit form
    </button>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "UpdateStatus",
  data() {
    return {
      searchQuery: "",
      submitFormState: false,
      showInfoBox: false,
    };
  },
  created() {
    this.getServiceOrder(this.$route.params.service_order_id);
    this.getServiceStatuses(this.serviceResource);
  },
  watch: {
    routeParam() {
      if (this.routeParam) {
        this.getServiceOrder(this.$route.params.service_order_id);
        this.getServiceStatuses(this.serviceResource);
      }
    },
  },
  computed: {
    ...mapState("service", ["serviceStatuses", "serviceOrder"]),
    routeParam() {
      return this.$route.params.service_order_id;
    },
    orderStatus() {
      if (
        this.serviceOrder &&
        this.serviceOrder.data &&
        this.serviceOrder.data.service_items.find(
          (e) => e.id == this.$route.params.service_item_id
        )
      ) {
        var serviceItem = this.serviceOrder.data.service_items.find(
          (e) => e.id == this.$route.params.service_item_id
        );
        return serviceItem.service_status;
      }
      return "";
    },
    listOfServiceStatuses() {
      if (
        this.serviceStatuses &&
        this.serviceStatuses.data &&
        this.serviceStatuses.data.length
      ) {
        return this.serviceStatuses.data;
      }

      return null;
    },
    serviceItem() {
      if (
        this.serviceOrder &&
        this.serviceOrder.data &&
        this.serviceOrder.data.service_items
      ) {
        return this.serviceOrder.data.service_items.find(
          (el) => el.id == this.$route.params.service_item_id
        );
      }

      return null;
    },
    price() {
      if (this.serviceItem) {
        return this.serviceItem.price + " " + this.serviceItem.currency;
      }
      return "";
    },
  },
  methods: {
    ...mapActions("service", [
      "setServiceStatus",
      "getServiceStatuses",
      "updateServiceStatus",
      "getServiceOrder",
      "updateServiceStatus",
    ]),
    checked(name) {
      return this.orderStatus == name;
    },
    showStatus(currentStatus) {
      if (this.orderStatus == "ACKNOWLEDGED") {
        if (Array("ORDER").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "PREPARATION") {
        if (Array("ORDER", "ACKNOWLEDGED").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "DELIVERED") {
        if (
          Array("ORDER", "ACKNOWLEDGED", "PREPARATION").includes(currentStatus)
        ) {
          return false;
        }
      }
      if (this.orderStatus == "RECEIVED") {
        if (
          Array("ORDER", "ACKNOWLEDGED", "PREPARATION", "DELIVERED").includes(
            currentStatus
          )
        ) {
          return false;
        }
      }
      return true;
    },
    disableStatus(currentStatus) {
      if (this.orderStatus == "ORDER") {
        if (Array("ACKNOWLEDGED").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "ACKNOWLEDGED") {
        if (Array("PREPARATION").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "PREPARATION") {
        if (Array("DELIVERED").includes(currentStatus)) {
          return false;
        }
      }
      if (this.orderStatus == "DELIVERED") {
        if (Array("RECEIVED").includes(currentStatus)) {
          return false;
        }
      }
      return false;
    },
    statusUpdate(status) {
      if (!this.serviceOrder.data.deleted_at) {
        if (!this.disableStatus(status)) {
          this.updateServiceStatus({
            order: this.serviceOrder.data.order_id,
            item: this.$route.params.service_item_id,
            status: status,
            refresh: true,
          });

          this.$router.push({
            name: "r_services-update-order-status",
            params: {
              service_order_id: this.serviceOrder.data.order_id,
            },
          });
        }
      }
    },
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
};
</script>
<style>
.order-services {
  display: flex;
  flex-direction: column;
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  background-color: white;
  margin-top: 27px;
  overflow: hidden;
}
.order-service {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-top: 1px solid lightgray;
}
.order-service:first-child {
  border-top: none;
}
</style>
